<script>
import { get } from 'vuex-pathify'
import deviceDetect from '../device-detect'

export default {
	lang: 'account',
	trackPageView: true,
	data() {
		return {
			loading: true,
			projects: [],
			editLoading: null,
			deleteLoading: null,
			previewLoading: null,
			pagination: {},
			duplication: {
				dialog: false,
				id: null,
				projectname: null,
				loading: false,
				validation: {},
			},
			page: 1,
			search: '',
			lastQuery: '',
			customStatus: '',
		}
	},
	computed: {
		user: get('shop/user'),
		btnsDisabled() {
			return (
				!!this.editLoading ||
				!!this.deleteLoading ||
				!!this.duplication.loading ||
				!!this.loading ||
				!!this.previewLoading
			)
		},
		zeroProjects() {
			return !this.projects.length && !this.customStatus && !this.search
		},
	},
	methods: {
		async fetchProjects({ page, search, customStatus, force = false, scrollTop = true } = {}) {
			if (page === undefined) page = this.page
			page = page || 1

			if (search === undefined) search = this.search
			search = String(search || '')
				.trim()
				.slice(0, 40)

			if (customStatus === undefined) customStatus = this.customStatus
			const query = {
				itemsPerPage: 5,
				paginated: true,
				page,
				filters: { search, customStatus },
				sortBy: ['id'],
				sortDesc: [true],
			}

			let queryJson = JSON.stringify(query)
			if (this.lastQuery == queryJson && !force) return
			this.lastQuery = queryJson

			if (scrollTop) window.scrollTo(0, 0)

			await this.$shopApi.get({
				url: `/taopix/projects`,
				query,
				loading: (v) => (this.loading = v),
				onSuccess: ({ data }) => {
					this.projects = data.items
					this.$assign(this.pagination, data.pagination)
					this.page = this.pagination.page
				},
			})
		},
		async editProject(projectId, withConfirm = true) {
			let confirm
			if (withConfirm) {
				confirm = {
					title: 'Editar y Agregar al Carrito',
					htmlText: `<div>Al confirmar esta acción serás redirigido a la pantalla de edición de tu Proyecto.<br /><br />Una vez realizados los cambios, <b>haz click en "Pedir"</b> y confirma todos los detalles de tu Proyecto.<br /><br />De esta manera, <b>tu producto se agregará nuevamente al Carrito</b> con los cambios aplicados.<div>`,
					accept: 'Si, continuar',
					cancel: 'Cancelar',
				}
			}

			await this.$shopApi.post({
				url: `/taopix/projects/edit/${projectId}`,
				loading: (v) => (this.editLoading = v ? projectId : null),
				data: {
					deviceDetection: deviceDetect(),
				},
				onSuccess: ({ data }) => {
					window.location.href = data.designurl
					return new Promise()
				},
				confirm,
			})
		},
		async previewProject(projectId) {
			await this.$shopApi.post({
				url: `/taopix/projects/preview/${projectId}`,
				loading: (v) => (this.previewLoading = v ? projectId : null),
				data: {
					deviceDetection: deviceDetect(),
				},
				onSuccess: ({ data }) => {
					window.open(data.designurl, '_blank')
				},
			})
		},
		openDuplicationDialog(project) {
			this.$assign(this.duplication, {
				id: project.id,
				projectname: project.projectname + ` (copia)`,
				dialog: true,
				loading: false,
			})
		},
		async duplicateProject() {
			if (!this.duplication.dialog || !this.duplication.id) return
			await this.$shopApi.post({
				url: `/taopix/projects/duplicate/${this.duplication.id}`,
				loading: (v) => (this.duplication.loading = v),
				onValidation: ({ validation }) => (this.duplication.validation = validation),
				data: {
					projectname: this.duplication.projectname,
				},
				onSuccess: async ({ data }) => {
					await this.editProject(data.projectId, false)
				},
			})
		},
		async deleteProject(projectId, projectname) {
			await this.$shopApi.post({
				url: `/taopix/projects/delete/${projectId}`,
				loading: (v) => (this.deleteLoading = v ? projectId : null),
				onSuccess: async () => {
					await this.fetchProjects({ force: true, scrollTop: false })
				},
				confirm: {
					title: `Eliminar Proyecto "${projectname}"`,
					text: 'El proyecto será eliminado definitivamente. ¿Desea continuar?',
					accept: 'Sí, eliminar',
				},
			})
		},
	},
	watch: {
		page(value) {
			if (value === this.pagination.page) return
			this.fetchProjects({ page: value })
		},
		customStatus(value) {
			this.fetchProjects({ customStatus: value, page: 1 })
		},
	},
	mounted() {
		this.fetchProjects()
	},
}
</script>

<template>
	<UserpanelLayout title="Mis Proyectos">
		<div>
			<v-row>
				<v-col cols="12" class="d-flex align-sm-center flex-column flex-sm-row" v-if="!zeroProjects">
					<div class="d-flex align-center pb-4 pb-sm-0">
						<v-text-field
							v-model="search"
							clearable
							:style="{ width: $b.m ? '350px' : '100%' }"
							class="mr-2"
							outlined
							placeholder="Buscar proyecto..."
							dense
							hide-details
							@click:clear="fetchProjects({ page: 1, search: '' })"
						/>
						<Button color="link" @click="fetchProjects({ page: 1 })">
							<v-icon left>mdi-magnify</v-icon> Buscar
						</Button>
					</div>
					<v-spacer />
					<div class="d-flex align-center justify-center justify-sm-end flex-wrap flex-sm-nowrap">
						<Button
							small
							color="link"
							:outlined="customStatus != ''"
							@click="customStatus = ''"
							class="mr-3 mb-3 mr-sm-2 mb-sm-0"
							>Todos</Button
						>
						<Button
							small
							color="link"
							:outlined="customStatus != 'active'"
							@click="customStatus = 'active'"
							class="mr-3 mb-3 mr-sm-2 mb-sm-0"
							>En curso</Button
						>
					</div>
					<div class="d-flex align-center justify-center justify-sm-end flex-wrap flex-sm-nowrap">
						<Button
							small
							color="link"
							:outlined="customStatus != 'pendingPayment'"
							@click="customStatus = 'pendingPayment'"
							class="mr-3 mb-3 mr-sm-2 mb-sm-0"
							>Pendientes de Pago</Button
						>
						<Button
							small
							color="link"
							:outlined="customStatus != 'paid'"
							@click="customStatus = 'paid'"
							class="mb-3 mb-sm-0"
							>Comprados</Button
						>
					</div>
				</v-col>
				<v-col v-if="loading && !deleteLoading">
					<v-progress-linear indeterminate color="link" />
				</v-col>
				<template v-else-if="projects.length">
					<v-col cols="12" md="10" v-for="project of projects" :key="project.id">
						<v-card height="100%">
							<v-card-actions
								class="px-4 py-3"
								v-if="project.orderId"
								:class="{ success: !!project.taopixOrderId, warning: !project.taopixOrderId }"
							>
								<b v-if="project.taopixOrderId">¡Compraste este Proyecto!</b>
								<b v-else>Pendiente de pago</b>
								<v-spacer />
								<Button
									color="link"
									small
									:to="{ name: 'user.order-detail', params: { id: project.orderId } }"
								>
									{{
										!project.taopixOrderId
											? 'Realizar Pago'
											: $b.m
											? 'Ver Orden'
											: 'Ver detalle de Orden'
									}}
								</Button>
							</v-card-actions>
							<v-card-title class="d-flex align-center">
								<div>{{ project.projectname }}</div>
								<v-spacer />
								<div class="font-0 grey--text">{{ project.projectref }}</div>
							</v-card-title>
							<v-card-text class="d-flex align-start">
								<Media
									class="flex-shrink-0"
									:src="project.variant.product.images[0].square"
									width="75px"
									aspect-ratio="1"
								/>
								<div class="w100 px-4">
									<div>
										<b class="font-1">{{ project.variant.product.name }}</b>
									</div>
									<div class="grey--text" v-if="project.item && project.item.pagecount > 1">
										{{ project.item.pagecount }} páginas
									</div>
								</div>
								<Media
									class="flex-shrink-0"
									v-if="project.image"
									:src="project.image"
									width="80px"
									aspect-ratio="1"
								/>
							</v-card-text>
							<v-card-actions class="px-4 py-3" style="border-top: 1px solid #ddd" v-if="$b.td">
								<Button
									color="error"
									outlined
									small
									:loading="deleteLoading == project.id"
									v-if="!project.orderId"
									:disabled="btnsDisabled && deleteLoading != project.id"
									@click="deleteProject(project.id, project.projectname)"
									class="mr-1"
								>
									<v-icon small left>mdi-delete-outline</v-icon> Eliminar
								</Button>

								<Button
									color="grey darken-3"
									outlined
									small
									:disabled="btnsDisabled"
									@click="openDuplicationDialog(project)"
									class="mr-1"
								>
									<v-icon small left>mdi-content-duplicate</v-icon> Duplicar
								</Button>
								<Button
									color="orange"
									outlined
									small
									:loading="previewLoading == project.id"
									:disabled="btnsDisabled && previewLoading != project.id"
									@click="previewProject(project.id)"
									class="mr-1"
								>
									<v-icon small left>mdi-eye</v-icon> Previsualizar
								</Button>
								<v-spacer />
								<Button
									color="link"
									outlined
									:loading="editLoading == project.id"
									:disabled="btnsDisabled && editLoading != project.id"
									@click="editProject(project.id)"
								>
									<v-icon small left>mdi-pencil</v-icon> Editar / Agregar al Carrito
								</Button>
							</v-card-actions>
							<v-card-actions class="pa-0" style="border-top: 1px solid #ddd" v-if="$b.m">
								<v-menu open-on-click bottom offset-y close-on-content-click>
									<template #activator="{ on, attrs }">
										<Button
											block
											text
											color="link"
											v-on="on"
											v-bind="attrs"
											:disabled="btnsDisabled"
											:loading="
												deleteLoading == project.id ||
												previewLoading == project.id ||
												editLoading == project.id
											"
										>
											Acciones <v-icon right>mdi-chevron-down</v-icon>
										</Button>
									</template>
									<v-list>
										<v-list-item
											v-if="!project.orderId"
											outlined
											small
											@click="deleteProject(project.id, project.projectname)"
										>
											<v-icon small left>mdi-delete-outline</v-icon> Eliminar
										</v-list-item>
										<v-list-item
											outlined
											small
											@click="openDuplicationDialog(project)"
											class="mr-2"
										>
											<v-icon small left>mdi-content-duplicate</v-icon> Duplicar
										</v-list-item>
										<v-list-item outlined small @click="previewProject(project.id)" class="mr-2">
											<v-icon small left>mdi-eye</v-icon> Previsualizar
										</v-list-item>
										<v-list-item color="link" outlined small @click="editProject(project.id)">
											<v-icon small left>mdi-pencil</v-icon> Editar / Agregar al Carrito
										</v-list-item>
									</v-list>
								</v-menu>
							</v-card-actions>
						</v-card>
					</v-col>
					<v-col cols="12">
						<v-pagination v-model="page" :length="pagination.lastPage" />
					</v-col>
				</template>
				<v-col v-else class="text-center py-16">
					<v-icon x-large color="grey lighten-1">mdi-heart-broken</v-icon>
					<div class="font-weight-bold font-2 mb-4 preline">
						{{ 'No hemos encontrado resultados\npara la licencia activa' | lang }}
					</div>
					<template v-if="zeroProjects">
						<div class="mb-4 font-2">{{ 'Creá tu primer Proyecto...' | lang }}</div>
						<div>
							<Button class="cta" large @click="$shop.goShop()">
								{{ 'Ir a la Tienda' | lang }}
							</Button>
						</div>
					</template>
				</v-col>
			</v-row>

			<v-dialog v-if="projects.length" v-model="duplication.dialog" width="450px" max-width="95%">
				<v-card>
					<v-card-title>Duplicar proyecto</v-card-title>
					<v-card-text class="py-3">
						<v-text-field
							v-model="duplication.projectname"
							outlined
							label="Nombre del Proyecto"
							:error-messages="duplication.validation.projectname"
							hide-details="auto"
							counter="60"
						/>
					</v-card-text>
					<v-card-actions class="align-center justify-end">
						<Button
							text
							small
							class="mr-2"
							@click="duplication.dialog = false"
							:disabled="btnsDisabled"
						>
							Cancelar
						</Button>
						<Button
							color="success"
							small
							@click="duplicateProject()"
							:loading="duplication.loading"
							:disabled="btnsDisabled && !duplication.loading"
						>
							Aceptar
						</Button>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</UserpanelLayout>
</template>
